import React from 'react';
import './StrengthsCard.css';
import styled from 'styled-components';

const StyledLi = styled.li`
    padding-left: var(--icon-space);
    font-family: 'Montserrat', sans-serif;
    margin: 15px 0;
    &::before {
        content: "${props => `\\${props.icon}`}";
        font-family: 'FontAwesome';
        font-size: 16px;
        display: inline-block;
        margin-left: -30px;
        width:30px;
        text-align: center;
    }
`;

// Ensure the variable `--icon-space` is also included in your component if not globally available
const StrengthsCard = ({ strengths }) => {
    return (
        <div className="strengths-card" style={{ '--icon-space': '1.3em' }}>
            <h2 className="strengths-title">STRENGTHS</h2>
            <ul className="strengths-list">
                {strengths.map((ab, index) => (
                    <StyledLi key={index} icon={ab.icon}>
                        <span className="strengths-list-header">{ab.header}</span><br/>
                        <span>{ab.description}</span>
                    </StyledLi>
                ))}
            </ul>
        </div>
    );
};

export default StrengthsCard;
