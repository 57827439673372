import React from 'react';
import "./Achievements.css"
import TextSeparator from "../TextSeparator/TextSeparator";

const Achievements = ({
                          achievements
                      }) => {
    return (
        <div className="achievements-description">
            <TextSeparator text="Achievements"/>
            <ol>
                {achievements.map((ab, index) => (
                    <li key={index}>{ab}</li>
                ))}
            </ol>
        </div>)
};

export default Achievements;