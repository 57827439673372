import React from 'react';
import './TextSeparator.css';

function TextSeparator({text}) {
  return (
    <div className="text-separator">
      <span className="text-label">{text}</span>
      <hr />
    </div>
  );
}

export default TextSeparator;
