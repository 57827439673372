import React from 'react';
import TextSeparator from "../TextSeparator/TextSeparator";

const AboutMe = ({ about_me }) => {
  return (
    <div>
      <TextSeparator text="About Me"></TextSeparator>
      <div className="smallText">
        {about_me.map((ab, index) => (
          <p key={index} dangerouslySetInnerHTML={{ __html: ab }} className="indent-50"></p>
        ))}
      </div>
    </div>
  );
};

export default AboutMe;
