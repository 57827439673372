import React from 'react';
import './ContactInfo.css';

const ContactInfo = ({ email, linkedin_username, linkedin_url, phone}) => {
  return (
    <div className="item bottomLineSeparator">
      <h2>CONTACT</h2>
      <div className="smallText contact">
        <p>
          <i className="fa fa-phone contactIcon" aria-hidden="true"></i>
          {phone}
        </p>
        <p className="email-overflow">
          <i className="fa fa-envelope contactIcon" aria-hidden="true"></i>
          <a href={`mailto:${email}`}>{email}</a>
        </p>
        <p>
          <i className="fa fa-map-marker contactIcon" aria-hidden="true"></i>
          Nava Vadaj, Ahmedabad,
          <br />
          Gujarat, India
        </p>
        <p>
          <i className="fa fa-linkedin-square contactIcon" aria-hidden="true"></i>
          <a href={linkedin_url} target="_blank" rel="noopener noreferrer">
            {linkedin_username}
          </a>
        </p>
      </div>
    </div>
  );
};

export default ContactInfo;
