import React from 'react';
import './ProgressBar.css';

const CircularProgressBar = ({
  percentage,
  size,
  strokeWidth,
  trackColor,
  progressColor,
  textColor,
  legend, // Prop for legend
}) => {
  // Calculating SVG properties
  const svgSize = size + strokeWidth * 2; // Add strokeWidth to both sides
  const radius = size / 2; // Radius is half of the size
  const viewBox = `0 0 ${svgSize} ${svgSize}`;
  const circumference = 2 * Math.PI * radius;
  const strokeDasharray = `${circumference} ${circumference}`;
  const strokeDashoffset = circumference - (percentage / 100) * circumference;

  return (
    <div className="progress-container" style={{ width: size, height: size }}>
      <div className="circularProgressBar">
        <svg
          width={svgSize}
          height={svgSize}
          viewBox={viewBox}
        >
          <circle
            cx={svgSize / 2}
            cy={svgSize / 2}
            r={radius / 1.2}
            fill="transparent"
            stroke={trackColor}
            strokeWidth={strokeWidth / 3}
            strokeDasharray={strokeDasharray}
          />
          <circle
            cx={svgSize / 2}
            cy={svgSize / 2}
            r={radius}
            fill="transparent"
            stroke={progressColor}
            strokeWidth={strokeWidth}
            strokeDasharray={strokeDasharray}
            strokeDashoffset={strokeDashoffset}
            strokeLinecap="butt"
            style={{ transform: 'rotate(-90deg)', transformOrigin: '50% 50%' }}
          />
        </svg>
        <div className="percentage" style={{ color: textColor }}>
          {percentage}%
        </div>
      </div>
      {legend && (
        <div className="progress-bar-legend">
          <span>{legend}</span>
        </div>
      )}
    </div>
  );
};

export default CircularProgressBar;
