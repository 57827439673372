import React from 'react';
import data from './data/data.json';
import ResumeContainer from './components/ResumeContainer/ResumeContainer';
import {Page} from "@react-pdf/renderer";
import ResumeContainer2 from "./components/ResumeContainer2/ResumeContainer";

const App = () => {
    const handlePrint = () => {
        window.print();
    };
    return (
        <div>
            <Page Size="A4">
                <ResumeContainer {...data} />
                <button className="floating-button no-print" onClick={handlePrint}><i className="fa fa-print" aria-hidden="true"></i><span
                    Style="padding: 0 10px;font-size:18px">Save as PDF</span>
                </button>
            </Page>
            <Page Size="A4">
                <ResumeContainer2 skills={data.skills} name={data.name} designation={data.designation} achievements={data.achievements} strengths={data.strengths}/>
            </Page>
        </div>
    )
        ;
};

export default App;
