import React from 'react';
import './ResumeContainer.css';
import SkillsMajor from "../SkillsMajor/SkillsMajor";
import Achievements from "../Achievements/Achievements";
import StrengthsCard from "../StrengthsCard/StrengthsCard";
// import myImage in "../../assets/img/mitali.png";
const ResumeContainer2 = ({
                              skills,
                              name,
                              designation,
                              achievements,
                              strengths
                          }) => {
    return (
        <div className="container">
            <div className="leftPanel">
                <img src={`${process.env.PUBLIC_URL}/mitali.png`} alt="Profile"/>
                <div className="details">
                    <StrengthsCard strengths={strengths}/>
                </div>
            </div>
            <div className="rightPanel">
                {/*<h1>{name}</h1>*/}
                {/*<h3>{designation}</h3>*/}

                <Achievements achievements={achievements}/>
                <SkillsMajor skills={skills.softskills} header="Soft Skills"/>
                <br></br>
                <br></br>
                <SkillsMajor skills={skills.techskills} header="Technical Skills"/>
            </div>
        </div>
    );
};

export default ResumeContainer2;
