import React from 'react';

const Certification = ({ certifications }) => {
  return (
    <div className="item">
      <h2>CERTIFICATIONS</h2>
      <div className="smallText certification">
        {certifications.map((cert, index) => (
          <div className="cert-item" key={index}>
            <p className="bolded white text-align-left">{cert.title}</p>
            <p>{cert.issuer}</p>
            <p>
              {cert.issue_date}
              {cert.expiry_date ? ` - ${cert.expiry_date}` : ''}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Certification;
