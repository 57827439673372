import React from 'react';
import TextSeparator from "../TextSeparator/TextSeparator";
import "../WorkExperience/WorkExperience.css"

const WorkExperience = ({experience}) => {
    return (
        <div className="workExperience">
            <TextSeparator text="Work Experiance"></TextSeparator>
            <ul>
                {experience.map((exp, index) => (
                    <li key={index}>
                        <div className="jobPosition">
                            <span className="bolded">{exp.company_name}</span>
                            <span>
                                {exp.start} - {exp.end}
                            </span>
                        </div>
                        <div className="projectName bolded">
                            <span>{exp.designation}</span>
                        </div>
                        <div className="exp-description">
                            <ol className="workExperience-description">
                                {exp.description.map((ab, index) => (
                                    <li key={index}>{ab}</li>
                                ))}
                            </ol>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default WorkExperience;
