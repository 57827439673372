import React from 'react';
import './SkillsMajor.css';
import ProgressBar from "../ProgressBar/ProgressBar";
import TextSeparator from "../TextSeparator/TextSeparator"; // CSS file for styling

const SkillsMajor = ({skills,header})  => {
    return (
        <div>
            <TextSeparator text={header}/>
            <div className="skills-major-container">
                <div className="skills-major-grid">
                    {skills.map(item => (
                        <div key={item.text} className="skill-item">
                            <ProgressBar
                                percentage={item.percentage}
                                size={80}
                                strokeWidth={4}
                                trackColor="#cccccc"
                                progressColor="#8A8A8A"
                                textColor="black"
                                legend={item.text}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default SkillsMajor;
